import React from 'react'
import Arrow from '../../../../../images/products/business/arrowIcon.svg'
import { Title } from '../../atoms'
import { ExploreWrap, ExploreContent, ExploreItem, LearnMore } from './atom'
import { exploreList } from '../../config'
import Path from '@raylink-overseas/common/constants/path'

export default function Explore({ ...rest }) {
  return (
    <ExploreWrap {...rest}>
      <Title>Explore Popular Features to Boost Efficiency</Title>
      <ExploreContent>
        <div className="e-list">
          {exploreList.map(item => (
            <ExploreItem key={item.title}>
              <div className="title-div">
                <img
                  src={item.imgSrc}
                  className="e-icon"
                  alt=""
                  loading="lazy"
                />
                <span>{item.title}</span>
              </div>
              <div className="desc">{item.desc}</div>
            </ExploreItem>
          ))}
        </div>
      </ExploreContent>
      <LearnMore>
        <a href={Path.allFeatures} target="_blank" rel="noreferrer">
          Learn More Features
        </a>
        <img className="arrow-icon" alt="" src={Arrow} loading="lazy" />
      </LearnMore>
    </ExploreWrap>
  )
}
